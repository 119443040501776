import BaseService from "./baseService"

export default class AssetService extends BaseService {
    getMenuData() {
        return this.get('asset/menuData')
    }
    getAssets(filters) {
        return this.getFiltered('asset/assets', filters)
    }
    getAssetCoordinates(filters) {
        return this.getFiltered('asset/coordinates', filters)
    }
    getProductOptions(filters){
        return this.getFiltered('asset/products/options', filters)
    }
    getTerritoryManagerOptions(filters){
        return this.getFiltered('asset/territory_managers/options', filters)
    }
    getModelYearOptions(filters){
        return this.getFiltered('asset/model_years/options', filters)
    }
}