<template>
  <div>
    <div>
      <Sidebar
        title="Filters"
        :visible.sync="visible"
        position="right"
        style="max-width: 500px"
      >
        <h1><i class="fas fa-filter" />Filters</h1>
        <div class="side-bar-item">
          <label for="territory_manager">Territory Manager</label>
          <Dropdown
            v-model="territory_manager"
            :options="territoryManagerOptions"
            optionLabel="name"
          >
          </Dropdown>
        </div>
        <div class="side-bar-item">
          <label for="customer_name">Customer Name</label>
          <InputText id="customer_name" type="text" v-model="customer_name">
          </InputText>
        </div>
        <div class="side-bar-item">
          <label for="farm_name">Farm Name</label>
          <InputText id="farm_name" type="text" v-model="farm_name">
          </InputText>
        </div>
        <div class="side-bar-item">
          <label for="dealer">Dealer</label>
          <InputText id="dealer" type="text" v-model="dealer"> </InputText>
        </div>
        <div class="side-bar-item">
          <label>Product</label>
          <MultiSelect v-model="products" :options="productOptions">
          </MultiSelect>
        </div>
        <div class="side-bar-item">
          <label for="model_year">Model Year</label>
          <MultiSelect
            id="model_year"
            v-model="model_years"
            :options="modelYearOptions"
          >
          </MultiSelect>
        </div>
        <div class="side-bar-item">
          <label for="serial">Serial Number</label>
          <InputText id="serial" type="text" v-model="serial"> </InputText>
        </div>
        <div class="side-bar-item">
          <label for="address">Address</label>
          <InputText id="address" type="text" v-model="address"> </InputText>
        </div>
        <div class="side-bar-item">
          <label for="phone">Phone Number</label>
          <InputMask
            id="phone"
            mask="999-999-9999"
            v-model="phone"
            placeholder="999-999-9999"
          >
          </InputMask>
        </div>
        <br />
        <div class="side-bar-item">
          <Button
            label="Filter"
            icon="pi pi-filter"
            iconPos="right"
            @click="getAssets"
            class="p-button-report-light"
          />
        </div>
        <br />
        <div class="side-bar-item">
          <Button
            label="Clear Filters"
            icon="pi pi-refresh"
            iconPos="right"
            @click="onClearFilter"
            class="p-button-report-light"
          />
        </div>
      </Sidebar>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <div class="card">
          <Toolbar class="p-toolbar-report">
            <template slot="start">
              <!-- <Button
                icon="pi pi-chevron-left"
                class="p-button-rounded p-button-report"
                @click="$router.go(-1)"
              /> -->
              <h1>Asset Map</h1>
            </template>
            <template slot="end">
              <Button
                icon="pi pi-filter"
                @click="visible = !visible"
                class="p-button-report-light"
              />
            </template>
          </Toolbar>
          <GmapMap
            v-if="!loading"
            :center="center"
            :zoom="7"
            style="width: 100%; height: 80vh"
          >
            <Gmap-info-window
              :options="infoOptions"
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen = false"
            >
              <strong> Territory Manager: </strong> {{ asset.territory_manager
              }}<br />
              <strong> Customer: </strong> {{ asset.customer_name }}<br />
              <strong> Farm: </strong> {{ asset.farm_name }} <br />
              <strong> Address: </strong> {{ asset.address }} <br />
              <strong> Phone: </strong> {{ asset.phone }} <br />
              <strong> Product: </strong> {{ asset.product }} <br />
              <strong> Dealer: </strong> {{ asset.dealer }} <br />
              <strong> Model Year: </strong> {{ asset.model_year }} <br />
              <strong> Serial: </strong> {{ asset.serial }} <br />
            </Gmap-info-window>
            <GmapCluster zoomOnClick = true>
              <GmapMarker
                :key="index"
                :position="asset"
                :icon="getIcon(asset.icon)"
                v-for="(asset, index) in assets"
                @click="openInfoWindow(asset)"
              >
              </GmapMarker>
            </GmapCluster>
          </GmapMap>
          <ProgressSpinner v-else />
          <div class="legend">
            <div>
              <label
                style="
                  background-color: black;
                  color: white;
                  border-radius: 5px;
                "
                >Harrows</label
              >
            </div>
            <div>
              <label style="background-color: green; border-radius: 5px"
                >Grain Carts</label
              >
            </div>
            <div>
              <label style="background-color: red; border-radius: 5px"
                >Tracks</label
              >
            </div>
            <div>
              <label style="background-color: yellow; border-radius: 5px"
                >Ditchers</label
              >
            </div>
            <div>
              <label style="background-color: orange; border-radius: 5px"
                >Fertilizer Caddy</label
              >
            </div>
            <div>
              <label style="background-color: blue; border-radius: 5px"
                >Bale Processor</label
              >
            </div>
            <div>
              <label style="background-border-color: black; border-radius: 5px"
                >Multiple Products</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssetService from "../service/assetService";

export default {
  name: "AssetMap",
  data() {
    return {
      loading: false,
      infoWinOpen: false,
      infoOptions: {},
      infoWindowPos: {
        lat: null,
        long: null,
      },
      visible: false,
      asset: [],
      center: { lat: 49.1034981, lng: -97.583954999999 },
      currentPlace: null,
      assets: [],
      modelYearOptions: [],
      productOptions: [],
      territoryManagerOptions: [],
      territory_manager: "",
      customer_name: "",
      farm_name: "",
      dealer: "",
      products: "",
      model_years: "",
      serial: "",
      address: "",
      phone: "",
    };
  },
  assetService: null,
  created() {
    this.assetService = new AssetService();
  },
  mounted() {
    this.geolocate();
    this.getAssets();
    this.getMenuData();
  },
  methods: {
    onClearFilter() {
      (this.model_years = ""),
        (this.territory_manager = ""),
        (this.customer_name = ""),
        (this.farm_name = ""),
        (this.dealer = ""),
        (this.products = ""),
        (this.serial = ""),
        (this.address = ""),
        (this.phone = ""),
        this.getAssets();
    },
    getAssets() {
      this.loading = true;
      if (this.visible) {
        this.visible = !this.visible;
      }
      this.assetService
        .getAssets(this.getFilters())
        .then(($result) => {
          this.assets = $result.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getMenuData() {
      var filters = this.getFilters();
      this.assetService.getProductOptions(filters).then(($result) => {
        this.productOptions = $result.data;
      });
      this.assetService.getTerritoryManagerOptions(filters).then(($result) => {
        this.territoryManagerOptions = $result.data;
      });
      this.modelYearOptions[2];
      this.assetService.getModelYearOptions(filters).then(($result) => {
        this.modelYearOptions = $result.data;
      });
    },
    /*
     * iterate through filter list
     * remove empty values
     * return new filter list
     */
    getFilters() {
      var filters = [];

      if (this.territory_manager) {
        filters.push({
          key: "territory_manager",
          value: this.territory_manager.Id,
        });
      }
      if (this.customer_name) {
        filters.push({
          key: "customer_name",
          value: this.customer_name,
        });
      }
      if (this.farm_name) {
        filters.push({
          key: "farm_name",
          value: this.farm_name,
        });
      }
      if (this.dealer) {
        filters.push({
          key: "dealer",
          value: this.dealer,
        });
      }
      if (this.products) {
        filters.push({
          key: "product",
          value: this.products,
        });
      }
      if (this.model_years) {
        filters.push({
          key: "model_year",
          value: this.model_years,
        });
      }
      if (this.serial) {
        filters.push({
          key: "serial",
          value: this.serial,
        });
      }
      if (this.address) {
        filters.push({
          key: "address",
          value: this.address,
        });
      }
      if (this.phone) {
        filters.push({
          key: "phone",
          value: this.phone,
        });
      }
      return filters;
    },
    /*
    returns url for colord map pins
    determines color by asset product
    */
    getIcon(icon) {
      const baseUrl = "http://labs.google.com/ridefinder/images/";
      switch (icon) {
        case "Grain Carts":
          return `${baseUrl}mm_20_green.png`;
        case "Ditchers":
          return `${baseUrl}mm_20_yellow.png`;
        case "Harrows":
          return `${baseUrl}mm_20_black.png`;
        case "Tracks":
          return `${baseUrl}mm_20_red.png`;
        case "Bale Processor":
          return `${baseUrl}mm_20_blue.png`;
        case "Fertilizer Caddy":
          return `${baseUrl}mm_20_orange.png`;
        case "Multiple":
          return `${baseUrl}mm_20_white.png`;
        default:
          return `${baseUrl}mm_20_brown.png`;
      }
    },
    /*
    gets index from marker click event
    opens asset info window
    isolates particular asset in global variable
    builds infoWinHeader
    */
    openInfoWindow(asset) {
      this.infoWinOpen = !this.infoWinOpen;
      this.asset = asset;
      this.infoWindowPos = {
        lat: asset.lat,
        lng: asset.lng,
      };
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
  },
};
</script>
<style scoped>
div .main-container {
  display: flex;
  flex-direction: column;
}
.p-sidebar-content {
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  justify-content: center;
}
.side-bar-item {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}
.side-bar-product-item {
  display: flex;
  flex-direction: row;
}
.p-slider {
  margin-bottom: 10pt;
  margin-top: 10pt;
}
.legend {
  display: flex;
  justify-content: space-between;
  font-size: 120%;
  width: 850px;
}
.side-bar-button {
  padding-left: 1250px;
  padding-bottom: 12px;
}
</style>
